// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-first-appointment-tsx": () => import("./../src/pages/first-appointment.tsx" /* webpackChunkName: "component---src-pages-first-appointment-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-location-tsx": () => import("./../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-locations-tsx": () => import("./../src/templates/locations.tsx" /* webpackChunkName: "component---src-templates-locations-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-service-area-tsx": () => import("./../src/templates/serviceArea.tsx" /* webpackChunkName: "component---src-templates-service-area-tsx" */)
}

