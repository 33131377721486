module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121258376-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Modern Hearing Solutions · Hearing Aids in Cody and Powell WY","short_name":"modernhearingsolutions","description":"Modern Hearing Solutions provides hearing aids to help treat hearing loss in Cody and Powell Wyoming. We offer tinnitus treatment and hearing tests to help people with hearing problems. We provide unmatched service you can trust to hear what matters most.","start_url":"/","lang":"en","background_color":"#663399","theme_color":"#663399","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
